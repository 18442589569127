<template lang="pug">
  div
    upgradeTest

</template>

<script type="text/javascript">
import upgradeTest from '@/components/vip/upgradeTest';

export default {
  name: 'UpgradeTest',

  components: {
    upgradeTest,
  },

  data() {
    return {

    };
  },

  computed: {},

  watch: {},

  created() {

  },
};
</script>

<style lang="scss">
@import './upgradeTest';
@import '@/styles/transition';
</style>
