const UpgradeTestLang = {
  messages: {
    tw: {
      experience: '經驗值：',
      exp_change: '經驗值增減',
      exp_text: '經驗值達100%，即可申請升級挑戰',
      exp_text_over: 'Level9以上學員 \n 暫不支持升級挑戰',
      return_test_page: '回到挑戰',
      start_upgrade_test: '開始挑戰',
      explain_upgrade_test: '挑戰說明',
      Cumulative_exp: '  經驗值累積方式',
      one_on_one: '1對1課程(堂)',
      one_on_three: '微班級(堂)',
      seven_classes_week: '7天滿7堂獎勵',
      fifteen_classes_week: '7天滿15堂獎勵',
      exp_list: '經驗值歷程',
      date: '日期',
      note: '備註',
      test_list: '挑戰歷程',
      test_time: '挑戰時間',
      test_name: '測驗名稱',
      test_status: '測驗結果',
      option: '操作',
      type: '類別',
      view_detail: '查看詳解',
      submitted: '未批改',
      approved: '批改中',
      passed: '通過',
      failed: '未通過',
      upgrade_test: '升級挑戰',
    },
    en: {
      experience: 'Experience：',
      exp_change: '',
      exp_text: '',
      exp_text_over: '',
      return_test_page: 'Return Upgrade Test',
      start_upgrade_test: 'Start Upgrade Test',
      explain_upgrade_test: '',
      Cumulative_exp: '',
      one_on_one: '1on1 class',
      one_on_three: '1on3 class',
      seven_classes_week: '',
      fifteen_classes_week: '',
      exp_list: 'Experience List',
      date: 'Date',
      note: 'Note',
      test_list: 'Test List',
      test_time: 'Test Time',
      test_name: 'Test Name',
      test_status: 'Test Status',
      option: 'Option',
      type: 'Type',
      view_detail: 'View Detail',
      submitted: 'Submitted',
      approved: 'Approved',
      passed: 'Passed',
      failed: 'Failed',
      upgrade_test: 'Upgrade Test',
    },
  },
};

export {
  UpgradeTestLang
};
