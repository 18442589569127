var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "challenge-list"
  }, [_c('b-row', [_vm.challengeInfo ? _c('b-col', {
    staticClass: "left-side-box",
    attrs: {
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "mobile-top"
  }, [_vm.challengeInfo.user ? _c('div', {
    staticClass: "col-lg-12 col-md-5 col-6"
  }, [_c('h4', {
    staticClass: "vip-name"
  }, [_vm._v(_vm._s(_vm.challengeInfo.user.english_name))]), _c('div', {
    staticClass: "exp-circle"
  }, [_c('canvas', {
    staticClass: "canvas-exp",
    attrs: {
      "id": "canvas-exp",
      "height": "245"
    }
  }), _c('div', {
    staticClass: "circle-text"
  }, [_c('span', {
    staticClass: "exp-persent"
  }, [_vm._v(_vm._s(_vm.vip_exp) + "%")]), _c('br'), _c('span', {
    staticClass: "vip-level"
  }, [_vm._v("LEVEL " + _vm._s(_vm.challengeInfo.user.level))])])])]) : _vm._e(), _c('div', {
    staticClass: "col-lg-12 col-md-7 col-6 d-flex flex-column justify-content-center align-items-center"
  }, [_vm.challengeInfo.user && _vm.challengeInfo.user.level >= 9 ? _c('div', {
    staticClass: "exp-info"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('exp_text_over')))])]) : _c('div', {
    staticClass: "exp-info"
  }, [_c('span', {
    staticClass: "exp-title"
  }, [_vm._v(_vm._s(_vm.$t('experience')))]), _c('span', {
    staticClass: "vip-exp"
  }, [_vm._v(_vm._s(_vm.challengeInfo.now_exp) + " / " + _vm._s(_vm.challengeInfo.level_total_exp))]), _c('br'), _c('span', {
    staticClass: "exp-text"
  }, [_vm._v(_vm._s(_vm.$t('exp_text')))])]), _vm.challengeInfo.in_exam_flag ? _c('b-button', {
    staticClass: "mt-3 mb-0",
    attrs: {
      "variant": "primary",
      "to": {
        name: 'ViewExplain'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('return_test_page')))]) : _c('b-button', {
    staticClass: "mt-3 mb-0",
    attrs: {
      "variant": "primary",
      "disabled": !_vm.challengeInfo.rtn || _vm.challengeInfo.user.level >= 9,
      "to": {
        name: 'ViewExplain',
        params: {
          startTest: true
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('start_upgrade_test')))]), _c('b-button', {
    attrs: {
      "variant": "primary",
      "to": {
        name: 'ViewExplain',
        params: {
          startTest: false
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('explain_upgrade_test')))])], 1)])]) : _vm._e(), _vm.upgradeTestList && _vm.challengeInfo ? _c('b-col', {
    staticClass: "challenge-main",
    attrs: {
      "lg": "9"
    }
  }, [_c('div', {
    staticClass: "wuwow-card"
  }, [_vm.challengeInfo.user ? _c('div', {
    staticClass: "main-title",
    on: {
      "click": function ($event) {
        return _vm.showExpContent();
      }
    }
  }, [_c('span', [_vm._v("LV" + _vm._s(_vm.challengeInfo.user.level) + " " + _vm._s(_vm.$t('Cumulative_exp')))]), _c('i', {
    staticClass: "fa",
    class: [_vm.show_exp_content ? 'fa-caret-down' : 'fa-caret-up'],
    attrs: {
      "aria-hidden": "true"
    }
  })]) : _vm._e(), _vm.show_exp_content ? _c('div', {
    staticClass: "main-content"
  }, [_c('table', {
    staticClass: "exp-table table text-center"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('one_on_one')))]), _c('th', [_vm._v(_vm._s(_vm.$t('one_on_three')))]), _c('th', [_vm._v(_vm._s(_vm.$t('seven_classes_week')))]), _c('th', [_vm._v(_vm._s(_vm.$t('fifteen_classes_week')))])]), _c('tr', _vm._l(_vm.upgradeTestList.experience_cumulative, function (item, index) {
    return _c('td', {
      key: item.id
    }, [_vm._v("+" + _vm._s(_vm.upgradeTestList.experience_cumulative[index])), _c('span', {
      staticClass: "exp-unit"
    }, [_vm._v("exp")])]);
  }), 0)])]) : _vm._e()]), _c('br'), _vm.experienceList ? _c('div', {
    staticClass: "wuwow-card"
  }, [_c('div', {
    staticClass: "main-title",
    on: {
      "click": function ($event) {
        return _vm.showExpList();
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('exp_change')))]), _c('i', {
    staticClass: "fa",
    class: [_vm.show_exp_list ? 'fa-caret-down' : 'fa-caret-up'],
    attrs: {
      "aria-hidden": "true"
    }
  })]), _vm.show_exp_list ? _c('div', {
    staticClass: "main-content"
  }, [_c('table', {
    staticClass: "list-table table table-hover table-clsapc-record text-center"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('date')))]), _c('th', [_vm._v(_vm._s(_vm.$t('experience')))]), _c('th', [_vm._v(_vm._s(_vm.$t('type')))])]), _vm._l(_vm.experienceList.data, function (item) {
    return _c('tr', {
      key: item.id
    }, [_c('td', [_vm._v(_vm._s(item.date))]), _c('td', [_vm._v(_vm._s(item.points))]), _c('td', [_vm._v(_vm._s(item.type))])]);
  })], 2)]) : _vm._e(), _vm.experienceList && _vm.show_exp_list ? _c('div', {
    staticClass: "wuwow-card-footer"
  }, [_c('b-pagination', {
    attrs: {
      "align": "center",
      "total-rows": _vm.experienceList.total,
      "per-page": _vm.experienceList.per_page
    },
    on: {
      "input": function ($event) {
        return _vm.changePageExp();
      }
    },
    model: {
      value: _vm.exp_next_currentPage,
      callback: function ($$v) {
        _vm.exp_next_currentPage = $$v;
      },
      expression: "exp_next_currentPage"
    }
  })], 1) : _vm._e()]) : _vm._e(), _c('br'), _vm.upgradeTestList ? _c('div', {
    staticClass: "wuwow-card"
  }, [_c('div', {
    staticClass: "main-title",
    on: {
      "click": function ($event) {
        return _vm.showTestList();
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('test_list')))]), _c('i', {
    staticClass: "fa",
    class: [_vm.show_test_list ? 'fa-caret-down' : 'fa-caret-up'],
    attrs: {
      "aria-hidden": "true"
    }
  })]), _vm.show_test_list ? _c('div', {
    staticClass: "main-content"
  }, [_c('table', {
    staticClass: "list-table table table-hover table-clsapc-record text-center"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('test_time')))]), _c('th', [_vm._v(_vm._s(_vm.$t('test_name')))]), _c('th', [_vm._v(_vm._s(_vm.$t('test_status')))]), _c('th', [_vm._v(_vm._s(_vm.$t('option')))])]), _vm._l(_vm.upgradeTestList.data, function (item) {
    return _c('tr', {
      key: item.id
    }, [_c('td', [_vm._v(_vm._s(item.test_at))]), _c('td', [_vm._v("Lv" + _vm._s(item.target_level) + " " + _vm._s(_vm.$t('upgrade_test')))]), _c('td', [_vm._v(_vm._s(_vm.$t(item.status)))]), _c('td', [_c('b-button', {
      staticClass: "view-btn",
      attrs: {
        "to": {
          name: 'ViewExplain',
          params: {
            uti: item.upgrade_test_applications
          }
        },
        "disabled": item.status !== 'failed' && item.status !== 'passed',
        "variant": "primary"
      }
    }, [_vm._v(_vm._s(_vm.$t('view_detail')))])], 1)]);
  })], 2)]) : _vm._e(), _vm.upgradeTestList && _vm.show_test_list ? _c('div', {
    staticClass: "wuwow-card-footer"
  }, [_c('b-pagination', {
    attrs: {
      "align": "center",
      "total-rows": _vm.upgradeTestList.total,
      "per-page": _vm.upgradeTestList.per_page
    },
    on: {
      "input": function ($event) {
        return _vm.changePageList();
      }
    },
    model: {
      value: _vm.list_next_currentPage,
      callback: function ($$v) {
        _vm.list_next_currentPage = $$v;
      },
      expression: "list_next_currentPage"
    }
  })], 1) : _vm._e()]) : _vm._e()]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }