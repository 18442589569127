<template lang="pug">
  .challenge-list
    b-row
      b-col.left-side-box(lg='3' v-if='challengeInfo')
        .mobile-top
          .col-lg-12.col-md-5.col-6(v-if='challengeInfo.user')
            h4.vip-name {{challengeInfo.user.english_name}}
            .exp-circle
              canvas#canvas-exp.canvas-exp(height='245')
              .circle-text
                span.exp-persent {{vip_exp}}%
                br
                span.vip-level LEVEL {{challengeInfo.user.level}}
          .col-lg-12.col-md-7.col-6.d-flex.flex-column.justify-content-center.align-items-center
            .exp-info(v-if='challengeInfo.user && challengeInfo.user.level >= 9')
              span {{$t('exp_text_over')}}
            .exp-info(v-else='')
              span.exp-title {{$t(&apos;experience&apos;)}}
              span.vip-exp {{challengeInfo.now_exp}} / {{challengeInfo.level_total_exp}}
              br
              span.exp-text {{$t(&apos;exp_text&apos;)}}
            b-button.mt-3.mb-0(variant='primary' v-if='challengeInfo.in_exam_flag' :to="{ name: 'ViewExplain'}") {{$t(&apos;return_test_page&apos;)}}
            b-button.mt-3.mb-0(variant='primary' v-else='' :disabled='!challengeInfo.rtn || challengeInfo.user.level >= 9' :to="{ name: 'ViewExplain', params: { startTest: true }}") {{$t(&apos;start_upgrade_test&apos;)}}
            b-button(variant='primary' :to="{ name: 'ViewExplain', params: { startTest: false }}") {{$t(&apos;explain_upgrade_test&apos;)}}
      //- // 經驗值累績方式
      b-col.challenge-main(lg='9' v-if='upgradeTestList && challengeInfo')
        .wuwow-card
          .main-title(@click='showExpContent()' v-if='challengeInfo.user')
            span LV{{challengeInfo.user.level}} {{$t(&apos;Cumulative_exp&apos;)}}
            i.fa(aria-hidden='true' :class="[show_exp_content?'fa-caret-down':'fa-caret-up']")
          .main-content(v-if='show_exp_content')
            table.exp-table.table.text-center
              tr
                th {{$t(&apos;one_on_one&apos;)}}
                th {{$t(&apos;one_on_three&apos;)}}
                th {{$t(&apos;seven_classes_week&apos;)}}
                th {{$t(&apos;fifteen_classes_week&apos;)}}
              tr
                td(v-for='(item,index) in upgradeTestList.experience_cumulative' :key='item.id')
                  | +{{upgradeTestList.experience_cumulative[index]}}
                  span.exp-unit exp
        br
        .wuwow-card(v-if='experienceList')
          //- // 經驗值歷程
          .main-title(@click='showExpList()')
            span {{$t(&apos;exp_change&apos;)}}
            i.fa(aria-hidden='true' :class="[show_exp_list?'fa-caret-down':'fa-caret-up']")
          .main-content(v-if='show_exp_list')
            table.list-table.table.table-hover.table-clsapc-record.text-center
              tr
                th {{$t(&apos;date&apos;)}}
                th {{$t(&apos;experience&apos;)}}
                th {{$t(&apos;type&apos;)}}
              tr(v-for='item in experienceList.data' :key='item.id')
                td {{item.date}}
                td {{item.points}}
                td {{item.type}}
          .wuwow-card-footer(v-if='experienceList && show_exp_list')
            b-pagination(align='center' v-model='exp_next_currentPage' :total-rows='experienceList.total' :per-page='experienceList.per_page' @input='changePageExp()')
        br
        .wuwow-card(v-if='upgradeTestList')
          //- // 考試歷程
          .main-title(@click='showTestList()')
            span {{$t(&apos;test_list&apos;)}}
            i.fa(aria-hidden='true' :class="[show_test_list?'fa-caret-down':'fa-caret-up']")
          .main-content(v-if='show_test_list')
            table.list-table.table.table-hover.table-clsapc-record.text-center
              tr
                th {{$t(&apos;test_time&apos;)}}
                th {{$t(&apos;test_name&apos;)}}
                th {{$t(&apos;test_status&apos;)}}
                th {{$t(&apos;option&apos;)}}
              tr(v-for='item in upgradeTestList.data' :key='item.id')
                td {{item.test_at}}
                td Lv{{item.target_level}} {{$t(&apos;upgrade_test&apos;)}}
                td {{$t(item.status)}}
                td
                  b-button.view-btn(:to="{ name: 'ViewExplain', params: { uti: item.upgrade_test_applications }}" :disabled="item.status !== 'failed' && item.status !== 'passed'" variant='primary') {{$t(&apos;view_detail&apos;)}}
          .wuwow-card-footer(v-if='upgradeTestList && show_test_list')
            b-pagination(align='center' v-model='list_next_currentPage' :total-rows='upgradeTestList.total' :per-page='upgradeTestList.per_page' @input='changePageList()')

</template>

<script type="text/javascript">
import { UpgradeTestLang } from './lang.js';
// import ViewExplain from './viewExplain';

// 暫時放的假資料
import upgrade from '../../../../public/upgrade-test-list.json';

export default {
  name: 'UpgradeTest',

  components: {
    // ViewExplain,
  },

  i18n: UpgradeTestLang,

  data() {
    return {
      show_exp_content: true,
      show_exp_list: true,
      show_test_list: true,
      // vip_exp: '',
      exp_next_currentPage: 1,
      list_next_currentPage: 1,
      per_page: 1,

      // 假資料
      upgradeTestList: upgrade,
      experienceList: upgrade.experience_cumulative,
      challengeInfo: upgrade.challenge,
      vip_exp: 90,
    };
  },

  computed: {

  },

  watch: {},

  created() {
  },

  methods: {

  },
};
</script>
<style lang="scss" scope>
@import '@/components/vip/upgradeTest/upgradeTest';
</style>
